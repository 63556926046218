import React, { useState } from 'react';
import { Container, Typography, TextField, Button, Box, Snackbar, Alert } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import Footer from '../Footer';
import { db } from '../firebaseconfig'; // Assuming your Firestore setup is correct
import { collection, addDoc, doc, getDoc, setDoc } from 'firebase/firestore';

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const counterDocRef = doc(db, 'counters', 'contactResponsesCounter');
      const counterDoc = await getDoc(counterDocRef);

      let currentDocId = 1;
      if (counterDoc.exists()) {
        currentDocId = counterDoc.data().count + 1;
      }

      await addDoc(collection(db, 'contactResponses'), {
        ...formData,
        docId: currentDocId,
      });

      await setDoc(counterDocRef, { count: currentDocId });

      setOpenSnackbar(true);
      setFormData({ name: '', email: '', message: '' });
    } catch (error) {
      console.error('Error adding document: ', error);
    }
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  return (
    <>
    <Box 
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        background: "#000",
        p: 3,
      }}
    >
      <Container
        maxWidth="sm"
        sx={{
          mt: 8,
          p: 4,
          borderRadius: 3,
          bgcolor: 'rgba(255, 255, 255, 0.8)',
          backdropFilter: 'blur(10px)',
          boxShadow: 6,
          border: '1px solid rgba(255, 255, 255, 0.3)',
          position: 'relative',
        }}
      >
        <Typography
          variant="h4"
          align="center"
          sx={{ fontWeight: 'bold', color: '#333', mb: 4, fontFamily: 'Poppins, sans-serif' }}
        >
          Contact Us
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            label="Name"
            name="name"
            variant="outlined"
            margin="normal"
            value={formData.name}
            onChange={handleChange}
            required
            sx={{ mb: 3 }}
          />
          <TextField
            fullWidth
            label="Email"
            name="email"
            variant="outlined"
            margin="normal"
            value={formData.email}
            onChange={handleChange}
            required
            sx={{ mb: 3 }}
          />
          <TextField
            fullWidth
            label="Message"
            name="message"
            variant="outlined"
            multiline
            rows={4}
            margin="normal"
            value={formData.message}
            onChange={handleChange}
            required
            sx={{ mb: 4 }}
          />
          <Box textAlign="center">
            <Button
              type="submit"
              variant="contained"
              endIcon={<SendIcon />}
              sx={{
                px: 6,
                py: 1.5,
                background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
                color: 'white',
                fontWeight: 'bold',
                borderRadius: '25px',
                '&:hover': { backgroundColor: '#FF8E53' },
              }}
            >
              Send
            </Button>
          </Box>
        </form>
      </Container>

      {/* Footer placed at the bottom */}
      

      {/* Snackbar for notification */}
      <Snackbar open={openSnackbar} autoHideDuration={4000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
          Thank you! Your response has been successfully submitted.
        </Alert>
      </Snackbar>
    </Box>
    <Box mt="auto">
        <Footer />
      </Box>
    </>
  );
};

export default ContactUs;
