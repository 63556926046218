import React from "react";
import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Box,
  Paper,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import FormatListNumberedRoundedIcon from "@mui/icons-material/FormatListNumberedRounded";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import StarIcon from "@mui/icons-material/Star";

// Array of features to display in cards
const features = [
  {
    icon: <CheckCircleIcon fontSize="large" style={{ color: "#4caf50" }} />,
    title: "Verified Salons",
    description:
      "All listed salons are verified and trusted for high-quality beauty services.",
  },
  {
    icon: (
      <FormatListNumberedRoundedIcon
        fontSize="large"
        style={{ color: "#ff5722" }}
      />
    ),
    title: "Variety of Services",
    description:
      "Choose from a wide range of beauty services tailored to your needs.",
  },
  {
    icon: <EventAvailableIcon fontSize="large" style={{ color: "#2196f3" }} />,
    title: "Easy Booking",
    description: "Book appointments easily with our user-friendly interface.",
  },
  {
    icon: <StarIcon fontSize="large" style={{ color: "#ffc107" }} />,
    title: "Top-Rated Professionals",
    description:
      "Get services from top-rated professionals with proven track records.",
  },
];

const WhyChooseUs = () => {
  return (
    <Container
      sx={{
        mt: 10,
        py: 10,
        bgcolor: "#000", // Black background
        borderRadius: "24px",
        boxShadow: "0 20px 40px rgba(0, 0, 0, 0.6)",
        maxWidth: "1200px",
        textAlign: "center",
        color: "#fff", // White text color
        fontFamily: "'Poppins', sans-serif", // Professional font family
        px: { xs: 2, md: 4 },
      }}
    >
      <Typography
        variant="h3"
        gutterBottom
        sx={{
          fontWeight: 700,
          color: "#fff", // Light color for contrast
          mb: 4,
          textTransform: "uppercase",
          letterSpacing: "1px",
        }}
      >
        Why Choose Us
      </Typography>

      <Typography
        variant="h6"
        paragraph
        sx={{
          color: "#ddd", // Light grey for paragraph
          mb: 6,
          fontSize: "18px",
          maxWidth: "800px",
          mx: "auto",
          lineHeight: "1.7",
        }}
      >
        Discover the benefits of using our platform for booking your beauty
        appointments. We provide a seamless experience with top-rated services
        and verified professionals.
      </Typography>

      <Grid container spacing={4} justifyContent="center">
        {features.map((feature, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Card
              sx={{
                bgcolor: "#2222", // Darker card background
                color: "#fff",
                boxShadow: "0 15px 30px rgba(0, 0, 0, 0.7)",
                borderRadius: "16px",
                border:"1px solid #333",
                transition: "transform 0.3s ease, box-shadow 0.3s ease",
                cursor: "pointer",
                overflow: "hidden",
                "&:hover": {
                  transform: "translateY(-8px)",
                  boxShadow: "0 25px 50px rgba(0, 0, 0, 0.8)",
                },
              }}
            >
              <CardMedia
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "150px",
                  bgcolor: "#2222", // Slightly lighter background for icons
                  borderBottom: "1px solid #333",
                }}
              >
                {feature.icon}
              </CardMedia>
              <CardContent>
                <Typography
                  variant="h6"
                  component="div"
                  sx={{
                    fontWeight: 600,
                    mb: 2,
                    textAlign: "center",
                    color: "#fff", // White text for titles
                  }}
                >
                  {feature.title}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    textAlign: "center",
                    color: "#bbb", // Light grey for descriptions
                    minHeight: "60px",
                  }}
                >
                  {feature.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default WhyChooseUs;
