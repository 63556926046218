import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Container, Typography, Box } from '@mui/material';
import Footer from '../Footer';

const privacyPolicyMarkdown = `
# Privacy Policy

## Introduction

Welcome to Malon! This Privacy Policy explains how we collect, use, and share your personal information when you use our Service. By using our Service, you agree to the practices described in this policy.

## Information We Collect

### 1. Personal Information

To provide you with a better experience, we may ask for personal details such as your Name, Email, Contact Number, Date of Birth, and Gender. This information helps us improve our Service.

### 2. Location Data

We collect and use location data to provide location-based services such as suggesting nearby salons or personalizing your app experience based on your location. We do not share your location data with third parties unless required to provide these services or by law.

### 3. Cookies

Cookies are small files that can help improve your experience. While we don’t use cookies directly, third-party services we use might. You can choose to accept or reject cookies through your browser settings. Refusing cookies might affect how some parts of our Service work for you.

## How to Delete Your Data

If you want to delete your information, just email us at contactus@malon.in.

## Third-Party Services

Our app might use third-party services, like Facebook, Google, which may collect your information. You can find their privacy policies on their respective websites.

## Service Providers

We may work with other companies to help us run our Service. These companies might have access to your Personal Information, but they are only allowed to use it for the tasks we assign them and must keep it confidential.

## Security

We take your security seriously and use standard methods to protect your information. However, no online method is completely secure, so we can't guarantee absolute protection.

## Data Retention and Deletion Policy

We retain your personal data as long as necessary to provide our services. If you request deletion, we will securely erase your information within a reasonable timeframe.

## Links to Other Sites

Our Service may contain links to other websites. We’re not responsible for their content or privacy practices, so please review their policies if you visit them.

## Changes to This Policy

We may update this Privacy Policy occasionally. Please check this page from time to time for any changes. We’ll notify you of major changes by updating this page.

## Contact Us

If you have any questions or suggestions about our Privacy Policy, feel free to email us at contactus@malon.in.

For more detailed information, please visit our [Privacy Policy](https://your-website-link.com/privacy-policy).
`;

const markdownStyles = {
  h1: {
    fontSize: '2rem', // Reduced from 2.5rem to 2rem
    fontWeight: 'bold',
    color: '#FFFFFF',
    marginBottom: '15px',
    textAlign: 'center',
  },
  h2: {
    fontSize: '1.5rem', // Reduced from 2rem to 1.5rem
    fontWeight: 'bold',
    color: '#CCCCCC',
    marginBottom: '10px',
  },
  p: {
    fontSize: '0.9rem', // Reduced from 1rem to 0.9rem
    lineHeight: '1.6',
    color: '#E0E0E0',
    marginBottom: '10px',
  },
  ul: {
    paddingLeft: '20px',
    marginBottom: '15px',
  },
  li: {
    fontSize: '0.9rem', // Reduced from 1rem to 0.9rem
    color: '#E0E0E0',
    marginBottom: '5px',
  }
};

const PrivacyPolicy = () => {
  return (
    <Box
      sx={{
        backgroundColor: '#000000',
        minHeight: '100vh',
        padding: '40px 0',
      }}
    >
      <Container maxWidth="md">
        <Box
          sx={{
            padding: '30px',
            backgroundColor: '#1e1e1e',
            borderRadius: '12px',
            boxShadow: '0px 6px 12px rgba(0,0,0,0.3)',
            color: '#E0E0E0',
          }}
        >
          <ReactMarkdown
            components={{
              h1: ({ node, ...props }) => <Typography component="h1" sx={markdownStyles.h1} {...props} />,
              h2: ({ node, ...props }) => <Typography component="h2" sx={markdownStyles.h2} {...props} />,
              p: ({ node, ...props }) => <Typography component="p" sx={markdownStyles.p} {...props} />,
              ul: ({ node, ...props }) => <ul style={markdownStyles.ul} {...props} />,
              li: ({ node, ...props }) => <li style={markdownStyles.li} {...props} />,
            }}
          >
            {privacyPolicyMarkdown}
          </ReactMarkdown>
        </Box>
      </Container>
      <Footer />
    </Box>
  );
};

export default PrivacyPolicy;
