import React, { useState } from "react";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Drawer from "@mui/material/Drawer";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Link, useLocation } from "react-router-dom";
import img2 from "./image/malon.png";
import img3 from "./image/malontext.png";

const fontFamily = "Montserrat, sans-serif";



const logoHoverStyle = {
  transform: "scale(1.05)",
};

const Navbar = () => {
  const [open, setOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [navbarStyle, setNavbarStyle] = useState({
    backgroundColor: "rgba(0, 0, 0, 0)",
    borderBottom: "none",
    backdropFilter: "blur(0px)",
    boxShadow: "none",
  });
  const [textColor, setTextColor] = useState("#EEEEEE");
  const location = useLocation();

  React.useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setNavbarStyle({
          backgroundColor: "rgba(0, 0, 0, 0.9)",
          borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
          backdropFilter: "blur(10px)",
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.3)",
        });
        setTextColor("#FFFFFF");
      } else if (location.pathname === "/") {
        setNavbarStyle({
          backgroundColor: "rgba(0, 0, 0, 0)",
          borderBottom: "none",
          backdropFilter: "blur(0px)",
          boxShadow: "none",
        });
        setTextColor("#EEEEEE");
      } else {
        setNavbarStyle({
          backgroundColor: "rgba(0, 0, 0, 0.8)",
          borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
          backdropFilter: "blur(10px)",
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.3)",
        });
        setTextColor("#FFFFFF");
      }
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll();
    return () => window.removeEventListener("scroll", handleScroll);
  }, [location.pathname]);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const handleCloseDrawer = () => {
    setOpen(false);
  };

  return (
    <div>
      <AppBar
        position="fixed"
        sx={{
          boxShadow: navbarStyle.boxShadow,
          bgcolor: navbarStyle.backgroundColor,
          backdropFilter: navbarStyle.backdropFilter,
          transition:
            "background-color 0.3s ease-in-out, backdrop-filter 0.3s ease-in-out, border-bottom 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
        }}
      >
        <Container maxWidth="lg">
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexShrink: 0,
              maxHeight: 70,
              transition: "all 0.3s ease-in-out",
            }}
          >
            {/* Logo and Company Name */}
            <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
              <Link to="/" style={{ textDecoration: "none" }}>
                <Box
                  sx={{ display: "flex", alignItems: "center" }}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                >
                 
                 <img src={img3} alt="logotext" height={40} width={95}  />
                </Box>
              </Link>
            </Box>

            {/* Desktop Navigation Links */}
            <Box sx={{ display: { xs: "none", md: "flex" }, ml: 3 }}>
              {["Home", "About Us", "Contact Us"].map((text) => (
                <Link
                  key={text}
                  to={text === "Home" ? "/" : `/${text.replace(" ", "").toLowerCase()}`}
                  style={{ textDecoration: "none" }}
                >
                  <MenuItem sx={{ py: "6px", px: "12px" }}>
                    <Typography
                      style={{
                        color: textColor,
                        fontFamily: fontFamily,
                        fontWeight: 300,
                        fontSize: "14px",
                        letterSpacing: "0.8px",
                        textAlign: "center",
                      }}
                      variant="body2"
                    >
                      {text}
                    </Typography>
                  </MenuItem>
                </Link>
              ))}
            </Box>

            {/* Business Growth Button */}
            <Box sx={{ display: { xs: "none", md: "flex" }, alignItems: "center" }}>
              <Link to="/Saloonreg" style={{ textDecoration: "none" }}>
                <Button
                  variant="outlined"
                  sx={{
                    color: "#FFFFFF",
                    border: "1px solid #FFFFFF",
                    fontFamily: fontFamily,
                    fontWeight: 400,
                    fontSize: "16px",
                    padding: "8px 20px",
                    borderRadius: "20px",
                    textTransform: "none",
                    transition: "all 0.3s ease",
                    "&:hover": {
                      backgroundColor: "#FFFFFF",
                      color: "#333333",
                    },
                  }}
                >
                  Grow Business
                </Button>
              </Link>
            </Box>

            {/* Mobile Menu Icon */}
            <Box sx={{ display: { xs: "block", md: "none" } }}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={toggleDrawer(true)}
                sx={{ color: textColor }}
              >
                <MenuIcon />
              </IconButton>
              <Drawer
                anchor="top"
                open={open}
                onClose={toggleDrawer(false)}
                sx={{
                  "& .MuiDrawer-paper": {
                    backgroundColor: "rgba(0, 0, 0, 0.9)",
                    color: "#FFF",
                    height: "100vh",
                    backdropFilter: "blur(15px)",
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    p: 2,
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{ ml: 2, fontFamily: fontFamily, fontWeight: 300 }}
                  >
                    Menu
                  </Typography>
                  <IconButton edge="end" color="inherit" onClick={handleCloseDrawer}>
                    <CloseIcon />
                  </IconButton>
                </Box>
                <Divider sx={{ borderColor: "#444" }} />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    p: 2,
                  }}
                >
                  {["Home", "About Us", "Contact Us"].map((text) => (
                    <Link
                      key={text}
                      to={text === "Home" ? "/" : `/${text.replace(" ", "").toLowerCase()}`}
                      style={{ textDecoration: "none", width: "100%" }}
                    >
                      <MenuItem
                        onClick={handleCloseDrawer}
                        style={{
                          color: "#FFFFFF",
                          fontFamily: fontFamily,
                          fontWeight: 300,
                          fontSize: "18px",
                          textAlign: "center",
                          padding: "10px 0",
                          width: "100%",
                        }}
                      >
                        {text}
                      </MenuItem>
                    </Link>
                  ))}
                  <Divider sx={{ my: 1, borderColor: "#444" }} />
                  <Link to="/Saloonreg" style={{ textDecoration: "none", width: "100%" }}>
                    <Button
                      variant="outlined"
                      sx={{
                        color: "#FFFFFF",
                        border: "1px solid #FFFFFF",
                        fontFamily: fontFamily,
                        fontWeight: 400,
                        fontSize: "16px",
                        padding: "10px 20px",
                        borderRadius: "20px",
                        textTransform: "none",
                        width: "100%",
                        textAlign: "center",
                        "&:hover": {
                          backgroundColor: "#FFFFFF",
                          color: "#333333",
                        },
                      }}
                      onClick={handleCloseDrawer}
                    >
                      Grow Business
                    </Button>
                  </Link>
                </Box>
              </Drawer>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
};

export default Navbar;
