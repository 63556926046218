// firebase.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyC9wEzGrPTgUwck766ZzVmpJyrKAg0BKoQ",
    authDomain: "maloonweb.firebaseapp.com",
    projectId: "maloonweb",
    storageBucket: "maloonweb.appspot.com",
    messagingSenderId: "752294756707",
    appId: "1:752294756707:web:f6b672263aa400de301356",
    measurementId: "G-45DPYWE39W"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };
