import React, { Suspense, lazy, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSwipeable } from "react-swipeable";
import img1 from "../image/back3.jpg";
import img3 from "../image/back5.jpg";
import Typography from "@mui/material/Typography";
import Footer from "../Footer";
import Navbar from "../Navbar";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import img5 from "../image/playstore.png";
import img4 from "../image/apple.png";

// Lazy-loaded components
const LazyOurservices = lazy(() => import("./Ourservices"));
const LazyForSaloon = lazy(() => import("./ForSaloon"));
const LazyFaq = lazy(() => import("./Faq"));

function Home() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const images = [img1, img3];

  useEffect(() => {
    const slideInterval = setInterval(() => {
      setCurrentSlide((prev) => (prev === images.length - 1 ? 0 : prev + 1));
    }, 3000);
    return () => clearInterval(slideInterval);
  }, [images.length]);

  const handleSwipe = (direction) => {
    if (direction === "LEFT") {
      setCurrentSlide((prev) => (prev === images.length - 1 ? 0 : prev + 1));
    } else if (direction === "RIGHT") {
      setCurrentSlide((prev) => (prev === 0 ? images.length - 1 : prev - 1));
    }
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => handleSwipe("LEFT"),
    onSwipedRight: () => handleSwipe("RIGHT"),
  });

  const offers = [
    {
      title: "50% Discount!",
      description: "Exclusive for Maloon First-Time Users",
    },
    {
      title: "60% OFF for Autumn Season!",
      description: "Limited Time Offer. Book Now!",
    },
   
  ];
  

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);

  return (
    <>
      <section
        style={{
          background: "#000",
          overflow: "hidden",
          minHeight: "100vh",
          position: "relative",
        }}
      >
        <Navbar />

        <div
          style={{
            position: "relative",
            height: "80vh", // Adjust height for better image visibility on all devices
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            overflow: "hidden",
            ...swipeHandlers,
          }}
        >
          {/* Custom Carousel */}
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              zIndex: 1,
              display: "flex",
              transition: "transform 0.8s ease-in-out",
              transform: `translateX(-${currentSlide * 100}%)`,
            }}
          >
            {images.map((img, index) => (
              <div
                key={index}
                style={{
                  flexShrink: 0,
                  width: "100%",
                  height: "100%",
                  backgroundImage: `url(${img})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  position: "relative",
                }}
              >
                {/* Offer Text */}
                <div
                  style={{
                    position: "absolute",
                    top: "40%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    textAlign: "center",
                    width: "80%",
                    zIndex: 2,
                    
                    padding: "20px",
                   
                  }}
                >
                  <Typography
                    variant="h3"
                    style={{
                      color: "#FFFFFF",
                      fontSize: "2.5rem",
                      fontWeight: "700",
                      fontFamily: "'Poppins', sans-serif",
                      textShadow: "2px 2px 5px rgba(0, 0, 0, 0.7)",
                      letterSpacing: "1px",
                      marginBottom: "0.5rem",
                    }}
                  >
                    {offers[currentSlide].title}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    style={{
                      color: "#FFFFFF",
                      fontSize: "1.2rem",
                      fontWeight: "300",
                      fontFamily: "'Lato', sans-serif",
                      textShadow: "1px 1px 4px rgba(0, 0, 0, 0.6)",
                    }}
                  >
                    {offers[currentSlide].description}
                  </Typography>
                </div>

                {/* App Download Section */}
                <div
                  style={{
                    position: "absolute",
                    bottom: "30px",
                    left: "50%",
                    transform: "translateX(-50%)",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "15px",
                    zIndex: 2,
                    width: "80%",
                    maxWidth: "600px",
                    justifyContent: "center",
                    padding: "0 20px",
                  }}
                >
                  {/* Google Play Button */}
                  <Link
                    to="https://play.google.com/apps/test/com.maloon/2"
                    style={{ textDecoration: "none" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "#000",
                        padding: "10px 20px",
                        borderRadius: "8px",
                        transition: "background-color 0.3s ease, transform 0.3s ease",
                        cursor: "pointer",
                        flexBasis: "48%",
                      }}
                      onMouseEnter={(e) => {
                        e.currentTarget.style.backgroundColor = "#000";
                      }}
                      onMouseLeave={(e) => {
                        e.currentTarget.style.backgroundColor = "#000";
                      }}
                    >
                      <img
                        src={img5}
                        alt="Google Play"
                        style={{ height: "24px", marginRight: "8px" }}
                      />
                      <div>
                        <Typography
                          variant="body1"
                          style={{
                            color: "#FFFFFF",
                            fontWeight: "500",
                            fontSize: "0.75rem",
                          }}
                        >
                          GET IT ON
                        </Typography>
                        <Typography
                          variant="body1"
                          style={{
                            color: "#FFFFFF",
                            fontWeight: "700",
                            fontSize: "1.2rem",
                            marginTop: "-4px",
                          }}
                        >
                          Google Play
                        </Typography>
                      </div>
                    </div>
                  </Link>

                  {/* App Store Button */}
                  <Link
                    to="#"
                    onClick={handleOpenModal}
                    style={{ textDecoration: "none" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "#000",
                        padding: "10px 20px",
                        borderRadius: "8px",
                        transition: "background-color 0.3s ease, transform 0.3s ease",
                        cursor: "pointer",
                        flexBasis: "48%",
                      }}
                      onMouseEnter={(e) => {
                        e.currentTarget.style.backgroundColor = "#000";
                      }}
                      onMouseLeave={(e) => {
                        e.currentTarget.style.backgroundColor = "#000";
                      }}
                    >
                      <img
                        src={img4}
                        alt="App Store"
                        style={{ height: "24px", marginRight: "8px" }}
                      />
                      <div>
                        <Typography
                          variant="body1"
                          style={{
                            color: "#FFFFFF",
                            fontWeight: "500",
                            fontSize: "0.70rem",
                          }}
                        >
                          GET IT ON
                        </Typography>
                        <Typography
                          variant="body1"
                          style={{
                            color: "#FFFFFF",
                            fontWeight: "700",
                            fontSize: "1.2rem",
                            marginTop: "-4px",
                          }}
                        >
                          App Store
                        </Typography>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>

        <Suspense fallback={<div>Loading services...</div>}>
          <LazyOurservices />
        </Suspense>
        <Suspense fallback={<div>Loading salons...</div>}>
          <LazyForSaloon />
        </Suspense>
        <Suspense fallback={<div>Loading FAQ...</div>}>
          <LazyFaq />
        </Suspense>

        <Footer />

        {/* Modal for App Store Info */}
        <Modal open={modalOpen} onClose={handleCloseModal}>
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "#fff",
              padding: "20px",
              borderRadius: "10px",
              boxShadow: "0 8px 16px rgba(0, 0, 0, 0.4)",
              maxWidth: "90%",
              width: "400px",
            }}
          >
            <Typography variant="h6">App Coming Soon</Typography>
            <Typography variant="body2">
              We're working on the App Store version of the app. Please stay
              tuned!
            </Typography>
            <IconButton
              onClick={handleCloseModal}
              style={{ position: "absolute", top: "10px", right: "10px" }}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </Modal>
      </section>
    </>
  );
}

export default Home;
