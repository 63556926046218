import React from "react";
import { Container, Typography, Grid, Box } from "@mui/material";
import { styled } from "@mui/system";


// Styled components with enhanced UI/UX
const Root = styled("div")(({ theme }) => ({
  background: "#000", // Darker background for a more modern look
  color: "#ffffff",
  minHeight: "100vh",
  padding: "6rem 0", // More generous padding for spacing
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontFamily: '"Poppins", sans-serif', // Professional font family
}));

const ContentBox = styled(Box)(({ theme }) => ({
  backgroundColor: "#2222", // Dark background with a hint of contrast
  padding: "3rem",
  borderRadius: "25px",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  boxShadow: "0px 15px 50px rgba(0, 0, 0, 0.8)", // Stronger shadow for depth
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    padding: "2rem",
  },
}));

const LeftBox = styled(Box)(({ theme }) => ({
  flex: 1,
  marginRight: "2.5rem",
  [theme.breakpoints.down("sm")]: {
    marginRight: 0,
    marginBottom: "2rem",
  },
}));

const RightBox = styled(Box)({
  flex: 1,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const Title = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  marginBottom: "2rem",
  color: "#ffffff", // Light text color for visibility
  textAlign: "left",
  fontSize: "3rem", // Larger font size for prominence
  lineHeight: 1.2,
  [theme.breakpoints.down("sm")]: {
    fontSize: "2.5rem",
  },
}));

const Subtitle = styled(Typography)(({ theme }) => ({
  marginBottom: "2rem",
  color: "#b0b0b0", // Softer grey for subtitle contrast
  textAlign: "left",
  fontWeight: "400",
  fontSize: "1.5rem", // Slightly larger for better readability
  lineHeight: 1.6,
}));

const MissionCard = styled(Box)(({ theme }) => ({
  padding: "2rem",
  border:'1px solid #333',
  borderRadius: "16px",
  color: "#ffffff", 
  background: "#2222", // Dark card background with a subtle difference from main background
  boxShadow: "0px 8px 30px rgba(0, 0, 0, 0.3)", // Subtle shadow
  transition: "transform 0.3s ease, box-shadow 0.3s ease",
  "&:hover": {
    transform: "translateY(-10px)",
    boxShadow: "0px 16px 40px rgba(0, 0, 0, 0.4)", // Enhanced shadow on hover
    
  },
}));

const missionData = [
  {
    title: "All Salons in One Place",
    description:
      "We aim to provide a comprehensive listing of all salons in your area, making it easy for you to find and book the services you need.",
  },
  {
    title: "Seamless Appointment Scheduling",
    description:
      "With our easy-to-use interface, you can schedule appointments at the time that works best for you, ensuring a smooth and hassle-free experience.",
  },
  {
    title: "Trusted Reviews and Ratings",
    description:
      "Read genuine reviews and ratings from other users to make informed decisions about the salons and services you choose.",
  },
  {
    title: "Exclusive Offers and Discounts",
    description:
      "Enjoy exclusive offers and discounts available only on our platform, helping you save on your favorite beauty services.",
  },
  {
    title: "Expert Recommendations",
    description:
      "Get personalized recommendations from beauty experts to find the best services and salons that suit your needs.",
  },
  {
    title: "24/7 Customer Support",
    description:
      "Our dedicated customer support team is available 24/7 to assist you with any queries or issues you may have.",
  },
];

const MissionPage = () => {
  return (
    <Root>
      <Container>
        <ContentBox>
          {/* Left Side: Text Content */}
          <LeftBox>
            <Title variant="h3" component="h1">
              Our Mission
            </Title>
            <Subtitle variant="h5" component="h2">
              Empowering you to find and book salon services with ease,
              convenience, and confidence.
            </Subtitle>
            <Grid container spacing={4}>
              {missionData.map((mission, index) => (
                <Grid item xs={12} sm={6} key={index}>
                  <MissionCard>
                    <Typography
                      variant="h6"
                      component="h3"
                      gutterBottom
                      sx={{ fontWeight: "bold", color: "#ffcc00" }} // Highlighted title color
                    >
                      {mission.title}
                    </Typography>
                    <Typography
                      variant="body1"
                      component="p"
                      sx={{ display: "flex", alignItems: "center", color: "#e0e0e0" }} // Light grey text for description
                    >
                      {mission.description}
                    </Typography>
                  </MissionCard>
                </Grid>
              ))}
            </Grid>
          </LeftBox>

          {/* Right Side: Image */}
          
        </ContentBox>
      </Container>
    </Root>
  );
};

export default MissionPage;
