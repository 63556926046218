import React from "react";
import "../style/ourservices.css";
import Typography from "@mui/material/Typography";

import WhyChooseus from "./Whychooseus";
import Ourmission from "./Ourmission";




function Ourservices() {
  return (
    <>
      
        <div style={{ background: '#000'}}>
          
          
          <Typography>
            
            <WhyChooseus/>
          </Typography>
        
          <Typography>
            <Ourmission/>
          </Typography>
          
          
        </div>
        </>
        
    
  );
}
export default Ourservices;
