import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const faqs = [
  {
    question: "How do I book a service on malon?",
    answer:
      "You can book a service on malon by selecting the desired service, choosing a date and time, and proceeding to checkout for payment.",
  },
  {
    question: "Can I cancel my booking?",
    answer:
      "Yes, you can cancel your booking through the app. Please refer to our cancellation policy for more details.",
  },
  {
    question: "What payment methods are accepted?",
    answer:
      "Currently, we accept cash payments. In our next update, we will introduce online payment options including credit/debit cards, net banking, and digital wallets.",
  },
  {
    question: "How can I contact customer support?",
    answer:
      "You can contact our customer support team through the app or by email at 'contactus@malon.in'.",
  },
  {
    question: "Do you offer discounts for new users?",
    answer:
      "Yes, we offer discounts and special offers for new users. Keep an eye on our promotions section for the latest deals.",
  },
  {
    question: "Can I schedule appointments in advance?",
    answer:
      "Yes, you can schedule appointments in advance through the app. Select the preferred date and time during the booking process.",
  },
  {
    question: "Are there referral rewards?",
    answer:
      "Currently, we do not have referral rewards. However, we are excited to announce that a referral program with rewards is in the works and will be available in our next update. Stay tuned for more details!",
  },
  {
    question: "How do I apply a coupon code?",
    answer:
      "During checkout, you can enter your coupon code in the designated field to apply discounts to your booking.",
  },
  {
    question: "What happens if I miss my appointment?",
    answer:
      "If you miss your appointment, please contact customer support to reschedule or discuss further options.",
  },
];

export default function FAQ() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Container
      id="faq"
      sx={{
        pt: { xs: 6, sm: 14 },
        pb: { xs: 10, sm: 18 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 4, sm: 8 },
        background: '#000',
        borderRadius: '20px',
        boxShadow: '0 10px 30px rgba(0, 0, 0, 0.5)',
        padding: '40px',
      }}
    >
      <Typography
        component="h2"
        variant="h4"
        color="text.primary"
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
          fontFamily: 'Poppins, sans-serif',
          fontWeight: 'bold',
          color: '#FFFFFF',
        }}
      >
        Frequently Asked Questions
      </Typography>
      <Box sx={{ width: '100%' }}>
        {faqs.map((faq, index) => (
          <Accordion
            key={index}
            expanded={expanded === `panel${index}`}
            onChange={handleChange(`panel${index}`)}
            sx={{
              backgroundColor: '#2222',
              borderRadius: '10px',
              boxShadow: '0 5px 15px rgba(0, 0, 0, 0.5)',
              marginBottom: '15px',
              border: '1px solid #333',
              '&:before': { display: 'none' },
              '&.Mui-expanded': {
                border: '1px solid #444',
                boxShadow: '0 8px 20px rgba(0, 0, 0, 0.6)',
              },
              transition: 'box-shadow 0.3s ease, border-color 0.3s ease',
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: '#fff' }} />}
              aria-controls={`panel${index}-content`}
              id={`panel${index}-header`}
            >
              <Typography
                component="h3"
                variant="subtitle2"
                sx={{
                  fontWeight: 'bold',
                  fontFamily: 'Poppins, sans-serif',
                  color: '#FFFFFF',
                }}
              >
                {faq.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                variant="body2"
                gutterBottom
                sx={{
                  maxWidth: { sm: '100%', md: '80%' },
                  color: '#CCCCCC',
                  lineHeight: 1.8,
                  fontFamily: 'Roboto, sans-serif',
                }}
              >
                {faq.answer}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Container>
  );
}
