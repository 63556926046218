import React from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import { styled } from '@mui/system';

// Import your images
import iosImage1 from '../image/home.jpg';
import iosImage2 from '../image/salon.jpg';
import iosImage3 from '../image/lzone.jpg';
import iosImage4 from '../image/appoint.jpg'; // Your additional image
import Footer from '../Footer';

// Styled Components
const AboutUsContainer = styled(Box)(({ theme }) => ({
  minHeight: '100vh',
  padding: theme.spacing(8, 2),
  background: '#000', // Black background
  display: 'flex',
  alignItems: 'center',
  color: '#fff', // Text color for dark background
}));

const PromptBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  [theme.breakpoints.up('md')]: {
    paddingRight: theme.spacing(8),
  },
}));

const ImageBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(2),
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
}));

const EmulatorImage = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  maxWidth: '220px',
  margin: theme.spacing(2), // Add some margin between images
  backgroundColor: '#000', // Dark background behind images
 
  padding: theme.spacing(2),
  borderRadius: '20px', // More rounded corners
  boxShadow: '0 8px 30px rgba(0, 0, 0, 0.3)', // Enhanced shadow effect
  '& img': {
    width: '100%',
    height: 'auto',
    borderRadius: '16px',
    position: 'relative',
    zIndex: 2,
  },
}));

const AboutUs = () => {
  return (
    <>
      <AboutUsContainer>
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={6}>
              <PromptBox>
                <Typography variant="h2" component="h1" gutterBottom sx={{ fontWeight: 'bold' }}>
                  Welcome to Malon
                </Typography>
                <Typography variant="h6" paragraph sx={{ color: '#ccc' }}>
                  Malon is your one-stop platform to explore and book services at the best salons in town.
                  We bring you a curated list of top-rated salons, offering a wide range of services to
                  suit all your grooming needs.
                </Typography>
                <Typography variant="h6" paragraph sx={{ color: '#ccc' }}>
                  Our mission is to connect users with salons that offer exceptional service and a great experience.
                  With Malon, you can browse salons, read reviews, compare prices, and book appointments with just a few clicks.
                </Typography>
              </PromptBox>
            </Grid>
            <Grid item xs={12} md={6}>
              <ImageBox>
                <EmulatorImage>
                  <img src={iosImage1} alt="Salon Image 1" />
                </EmulatorImage>
                <EmulatorImage>
                  <img src={iosImage2} alt="Salon Image 2" />
                </EmulatorImage>
                <EmulatorImage>
                  <img src={iosImage3} alt="Salon Image 3" />
                </EmulatorImage>
                <EmulatorImage>
                  <img src={iosImage4} alt="Salon Image 4" />
                </EmulatorImage>
              </ImageBox>
            </Grid>
          </Grid>
        </Container>
      </AboutUsContainer>
      <Footer />
    </>
  );
};

export default AboutUs;
