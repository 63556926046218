// src/component/pages/NotFound.js
import React from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';


const NotFound = () => {
  return (
    <Box 
      sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        justifyContent: 'center', 
        height: '100vh', 
        bgcolor: '#000', // Black background
        color: '#fff', // White text color
        textAlign: 'center',
        padding: '20px',
      }}
    >
      
      <Typography variant="h1" sx={{ 
        fontSize: '5rem', 
        fontWeight: 'bold', 
        color: '#fff', 
        mb: 2 
      }}>
        404
      </Typography>
      <Typography variant="h4" sx={{ 
        color: '#ccc', 
        mb: 3 
      }}>
        Oops! Page Not Found
      </Typography>
      <Typography variant="body1" sx={{ 
        color: '#aaa', 
        mb: 4 
      }}>
        Sorry, the page you are looking for does not exist. It might have been moved or deleted.
      </Typography>
      <Link to="/" style={{ textDecoration: 'none' }}>
        <Button 
          variant="contained" 
          sx={{ 
            bgcolor: '#007BFF', 
            color: '#fff', 
            '&:hover': {
              bgcolor: '#0056b3'
            },
            borderRadius: '20px',
            padding: '10px 20px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)' // Add shadow for elevation effect
          }}
        >
          Go to Home
        </Button>
      </Link>
    </Box>
  );
};

export default NotFound;
