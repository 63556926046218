import React, { useState } from 'react';
import { db } from '../firebaseconfig';
import { collection, addDoc } from 'firebase/firestore';
import { TextField, Button, Typography, Container, Grid, Paper, MenuItem, Snackbar, Alert, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import Footer from '../Footer';

// Styled components using @mui/material/styles
const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  borderRadius: '12px',
  boxShadow: theme.shadows[6],
  background: 'rgba(255, 255, 255, 0.8)',
  backdropFilter: 'blur(10px)',
  border: '1px solid rgba(255, 255, 255, 0.3)',
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
  color: 'white',
  fontWeight: 'bold',
  borderRadius: '25px',
  '&:hover': {
    background: 'linear-gradient(45deg, #FF8E53 30%, #FE6B8B 90%)',
  },
}));

const SaloonRegistration = () => {
  const [formData, setFormData] = useState({
    ownerName: '',
    mobileNumber: '',
    email: '',
    saloonType: '',
    address: '',
    operatingTime: ''
  });

  const [notification, setNotification] = useState('');

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await addDoc(collection(db, 'saloons'), formData);
      setNotification('success');
      setFormData({
        ownerName: '',
        mobileNumber: '',
        email: '',
        saloonType: '',
        address: '',
        operatingTime: ''
      });
    } catch (error) {
      console.error('Error adding document: ', error);
      setNotification('error');
    }
  };

  const handleClose = () => {
    setNotification('');
  };

  return (
    <>
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        background: "#000",
        p: 3,
        pb: 8, // Padding bottom to avoid overlap
      }}
    >
      <Container component="main" maxWidth="sm" className="mt-6">
        <StyledPaper elevation={6}>
          <Typography variant="h4" component="h1" align="center" gutterBottom sx={{ fontFamily: 'Poppins, sans-serif' }}>
            Salon Registration
          </Typography>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  label="Owner Name"
                  name="ownerName"
                  value={formData.ownerName}
                  onChange={handleChange}
                  required
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  label="Mobile Number"
                  name="mobileNumber"
                  value={formData.mobileNumber}
                  onChange={handleChange}
                  required
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  label="Email"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  select
                  variant="outlined"
                  fullWidth
                  label="Salon Type"
                  name="saloonType"
                  value={formData.saloonType}
                  onChange={handleChange}
                  required
                  margin="normal"
                >
                  <MenuItem value="male">Male</MenuItem>
                  <MenuItem value="female">Female</MenuItem>
                  <MenuItem value="unisex">Unisex</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  label="Operating Time"
                  name="operatingTime"
                  value={formData.operatingTime}
                  onChange={handleChange}
                  required
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  label="Address"
                  name="address"
                  multiline
                  rows={4}
                  value={formData.address}
                  onChange={handleChange}
                  required
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12}>
                <StyledButton
                  type="submit"
                  fullWidth
                  variant="contained"
                >
                  Register Salon
                </StyledButton>
              </Grid>
            </Grid>
          </form>
        </StyledPaper>

        <Snackbar open={notification === 'success'} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success">
            Your salon registration has been successfully submitted!
          </Alert>
        </Snackbar>

        <Snackbar open={notification === 'error'} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error">
            Error submitting your registration. Please try again.
          </Alert>
        </Snackbar>
      </Container>

      {/* Footer placed at the bottom */}
     
    </Box>
    <Footer />
    </>
  );
};

export default SaloonRegistration;
